import styles from './ColoredButton.module.scss'
import { ReactNode } from 'react'

type Props = {
  children?: ReactNode
  type?: 'button' | 'submit' | 'reset' | undefined
  color?: string | undefined
  onClick?: () => void
  className?: string
  disabled?: boolean
}

const ColoredButton = ({ children, onClick, type, color, className, disabled }: Props) => (
  <button
    type={type}
    className={`${styles.button} ${className} button is-${color ? color : 'black'}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
)

export default ColoredButton
