import { createElement, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ErrorToast from 'src/components/ErrorToast'
import { captureException } from '@sentry/core'

type ShowErrorFn = (error: Error) => void

export default function useErrorToast(): ShowErrorFn {
  const [error, setError] = useState<Error>()
  useEffect(() => {
    if (error) {
      captureException(error)
      console.error(`${error.name}: ${error.message}${'\ncause: ' + error.cause}\n${error.stack}`)
      toast.error(createElement(ErrorToast, { error }), { autoClose: false })
    }
  }, [error])
  return (newError: Error) => {
    if (newError != error) {
      setError(newError)
    }
  }
}
