import { t } from 'i18next'
import styles from './Loading.module.scss'
import { Helmet } from 'react-helmet-async'

const Loading = () => {
  return (
    <div className={`loading ${styles.loading}`}>
      <Helmet>
        <title>{`Socialprofiler ${t('Loading...')}`}</title>
      </Helmet>
      <div className={styles['lds-ring']}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={'loading-text'}>{t('Loading...')}</div>
    </div>
  )
}

export default Loading
