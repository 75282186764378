import ColoredButton from '@socialprofiler/ui-kit/ColoredButton'
import { useTranslation } from 'react-i18next'
import Content from '@socialprofiler/ui-kit/Content'

type Props = {
  error: Error
}

const ErrorToast = ({ error }: Props) => {
  const { t: te } = useTranslation('', { keyPrefix: 'errors' })

  return (
    <Content>
      <strong>{te('toastTitle')}</strong>
      <p>{te('toastMessage', { errorMessage: error.message })}</p>
      <ColoredButton onClick={() => window.location.reload()}>
        {te('toastReloadButton')}
      </ColoredButton>
    </Content>
  )
}

export default ErrorToast
